import md5 from "crypto-js/md5";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import {
  createContext,
  Dispatch,
  MutableRefObject,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { useAuthContext } from "~/contexts/auth";
import { useConfiguration } from "~/contexts/configuration";
import { useCartProductsHierarchicalData, useCartWithPendingStatus } from "~/hooks/use-cart";
import useSubscriptions from "~/hooks/use-subscriptions";
import { BasePageTrackingObject, FreezeOn, PageTrackingOptions } from "~/types/trackings.models";
import { ClientUtils } from "~/utils/client-utils";
import crossCountryUtils from "~/utils/crossCountry-utils";
import loyaltyUtils from "~/utils/loyalty-utils";
import TrackingUtils from "~/utils/tracking-utils";

declare global {
  var dataLayer: any;
}

type ContextProps = PropsWithChildren;
type ContextAttrs =
  | {
      isPageTracked: boolean;
      setPageTracked: Dispatch<SetStateAction<boolean>>;
      isPendingStatus: boolean;
      setPendingStatus: Dispatch<SetStateAction<boolean>>;
      freezeOn: FreezeOn;
      setFreezeOn: Dispatch<SetStateAction<FreezeOn>>;
      deferredTrackings: MutableRefObject<Record<string, any>[]>;
    }
  | undefined;
const Context = createContext<ContextAttrs>(undefined);

export function PageTrackingsProvider(props: ContextProps) {
  const router = useRouter();
  const { isLoadingUser, isAuthenticated } = useAuthContext();
  const { isLoadingCart } = useCartWithPendingStatus();
  const { isLoadingUserSubscriptions } = useSubscriptions();

  const [isPageTracked, setPageTracked] = useState(false);
  const [isPendingStatus, setPendingStatus] = useState(true);
  const [freezeOn, setFreezeOn] = useState<FreezeOn>(undefined);
  const [isChanging, setChanging] = useState(false);
  const deferredTrackings = useRef([]);

  let globalStatus = useRef<any>(null);

  const _onRouteChange = (_url: string, { shallow }: { shallow: boolean }) => {
    globalStatus.current = null;
    if (!shallow) {
      setPageTracked(false);
    }
    setPendingStatus(true);
    setChanging(true);
    deferredTrackings.current = [];
  };

  const _onRouteChanged = (_url: string) => {
    setChanging(false);
  };

  useEffect(() => {
    router.events.off("routeChangeStart", _onRouteChange);
    router.events.off("routeChangeComplete", _onRouteChanged);
    router.events.on("routeChangeStart", _onRouteChange);
    router.events.on("routeChangeComplete", _onRouteChanged);
  }, [router.events]);

  useEffect(() => {
    const pendingStatus =
      isLoadingUser || isLoadingCart || (isAuthenticated && isLoadingUserSubscriptions) || isChanging;
    if (!isPageTracked && window.dataLayer && !pendingStatus) {
      globalStatus.current = ClientUtils.getAppGlobal("trackings", {});
      globalStatus.current.baseDone = isPageTracked;

      setPendingStatus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingUser, isLoadingCart, isLoadingUserSubscriptions, isChanging]);

  return (
    <Context.Provider
      value={{
        isPageTracked,
        setPageTracked,
        isPendingStatus,
        setPendingStatus,
        freezeOn,
        setFreezeOn,
        deferredTrackings,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

export function usePageTracking(pageType: string, options?: PageTrackingOptions) {
  const context = useContext(Context);
  if (!context) {
    throw new Error(`usePageTrackings must be used within a PageTrackingsProvider`);
  }

  const router = useRouter();
  const { configuration } = useConfiguration();
  const { user, isAuthenticated } = useAuthContext();
  const { cart } = useCartWithPendingStatus();
  const cartProductsParentsData = useCartProductsHierarchicalData();

  const { getUserSubscriptionByBackendId } = useSubscriptions();

  const t = useTranslations();

  let globalStatus = useRef<any>(null);

  useEffect(() => {
    if (
      !context.isPendingStatus &&
      !context.isPageTracked &&
      (!options?.freezeOn || context.freezeOn !== options.freezeOn) &&
      !options?.wait
    ) {
      let userLoggedIn = !!isAuthenticated;
      const asPath = router.asPath;
      const country = crossCountryUtils.getCurrentCountryCode(router);
      const cartItems = TrackingUtils.mapPageCartItems(cart?.ShoppingCart, cartProductsParentsData);

      const payload: BasePageTrackingObject = {
        event: "dl_update",
        page_type: pageType!,
        page_type_section: pageType!,
        page_url: asPath,
        region: country,
        user_logged_in: TrackingUtils.formatBoolean(userLoggedIn),
        visit_from_app: TrackingUtils.formatBoolean(false, true),
      };

      if (cartItems.length) {
        payload.incart_products = cartItems;
      }

      options?.title && (payload.page_name = options.title);

      if (isAuthenticated) {
        payload.cid = user!.ID?.toString();
        payload.customer_id = md5(user!.EmailAddress)?.toString(); // TODO: check if cryptographic-safe hash is needed

        const loyaltySubscription = getUserSubscriptionByBackendId("LoyaltyProgram");
        const isUserSubscribedToLoyalty = loyaltySubscription?.Status === 2;

        if (isUserSubscribedToLoyalty) {
          const loyaltyBalance = loyaltySubscription.Subscription?.LoyaltyBalance;
          const loyaltyLevel = loyaltyUtils.getUserLoyaltyTierName(user, configuration);
          payload.kisses_card_number = loyaltySubscription.UserIdentifier;
          payload.kisses_total = loyaltyBalance?.Points || 0;
          payload.kisses_level = loyaltyLevel ?? t("loyalty.basicLevel");
        }
      }

      payload.currency_code = (configuration as any)?.CurrencyID;

      TrackingUtils.track({ ...payload, ...options?.extension });
      globalStatus.current = ClientUtils.getAppGlobal("trackings", {});
      globalStatus.current.baseDone = true;
      window.dispatchEvent(new Event("trackings.base:done"));
      context.setPageTracked(true);
      context.setFreezeOn(options?.freezeOn); // freeze tracking until pagetype changes
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.isPendingStatus, context.isPageTracked, context.freezeOn, !!options?.wait]);

  return {
    isPageTracked: context.isPageTracked,
  };
}

export function usePageTracked(callback?: Function) {
  const context = useContext(Context);
  if (!context) {
    throw new Error(`usePageTracked must be used within a PageTrackingsProvider`);
  }

  useEffect(() => {
    callback && callback(context.isPageTracked);
  }, [callback, context.isPageTracked]);
  return context.isPageTracked;
}

export function usePageTrackingControls() {
  const context = useContext(Context);
  if (!context) {
    throw new Error(`usePageTrackingControls must be used within a PageTrackingsProvider`);
  }
  const forceStatus = (status: boolean) => {
    context.setPageTracked(status);
  };

  return { forceStatus };
}

export function useDeferredTracking(payload: Record<string, any>, callback?: Function) {
  const context = useContext(Context);
  if (!context) {
    throw new Error(`useDeferredTracking must be used within a PageTrackingsProvider`);
  }

  useEffect(() => {
    if (context.isPageTracked && payload && Object.keys(payload).length) {
      TrackingUtils.track(payload);
      callback && callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.isPageTracked]);
}
