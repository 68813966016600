import classNames from "classnames";
import { Entry } from "contentful";
import Image from "next/image";
import { ImgHTMLAttributes } from "react";

import Editorials from "~/types/editorials";
import CommonUtils from "~/utils/common-utils";
import Constants from "~/utils/constants";
import { isActiveEntry } from "~/utils/editorial-utils";
import imageUtils from "~/utils/image-utils";

import styles from "./media-asset.module.scss";

type Props = {
  entry: Entry<Editorials.Asset>;
  className?: string;
  fill?: boolean;
  width?: number;
  height?: number;
  videoConfig?: {
    muted?: boolean;
    loop?: boolean;
    autoPlay?: boolean;
    controls?: boolean;
    playsInline?: boolean;
  };
  noLazy?: boolean;
} & Omit<ImgHTMLAttributes<HTMLImageElement>, "placeholder">;

const defaultVideoConfig = { muted: true, loop: true, autoPlay: true, controls: true, playsInline: true };

// by deafult fill rule for next/image is set to true
export default function MediaAsset({
  entry,
  className,
  fill = true,
  width,
  height,
  videoConfig = defaultVideoConfig,
  noLazy,
  ...rest
}: Props) {
  if (!isActiveEntry(entry) || !entry.fields.file) return null;

  let mediaUrl = entry.fields.file.url;

  if (CommonUtils.parseBoolean(process.env.NEXT_PUBLIC_ENABLE_CLOUDFRONT_ASSETS)) {
    mediaUrl = mediaUrl.replace(
      Constants.ASSETS_CDN_IMAGES_SOURCE.CONTENTFUL,
      Constants.ASSETS_CDN_IMAGES_SOURCE.CLOUDFRONT
    );
    mediaUrl = mediaUrl.replace(
      Constants.ASSETS_CDN_VIDEOS_SOURCE.CONTENTFUL,
      Constants.ASSETS_CDN_VIDEOS_SOURCE.CLOUDFRONT
    );
  }

  return entry.fields.file.contentType.split("/")[0] === "image" ? (
    <Image
      priority={!!noLazy}
      src={"https:" + mediaUrl}
      alt={entry.fields.description ? entry.fields.description : entry.fields.title}
      className={className}
      fill={!(width || height) ? fill : undefined}
      width={width}
      height={height}
      unoptimized={mediaUrl.endsWith(".svg")}
      loader={imageUtils.contentfulImageLoader}
      {...rest}
    />
  ) : (
    <video
      {...videoConfig}
      className={classNames(styles.video, className)}
      src={"https:" + mediaUrl}
      preload={!!noLazy ? "auto" : undefined}
    />
  );
}
