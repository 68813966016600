import { atom, selector } from "recoil";

import CommonUtils from "~/utils/common-utils";

type SequentialLoadingState = {
  didomi: boolean;
  countryDetect: boolean;
  usablenet: boolean;
};

export const sequentialLoadingState = atom<SequentialLoadingState>({
  key: "sequentialLoadingState",
  default: {
    didomi: false,
    countryDetect: false,
    usablenet: false,
  },
});

export const countryDetectReadyState = selector({
  key: "sequentialLoadingCountryDetectState",
  get: ({ get }) => {
    const parentState = get(sequentialLoadingState);

    if (!parentState.didomi) {
      return false;
    }

    return true;
  },
});

export const usableNetReadyState = selector({
  key: "sequentialLoadingUsableNetState",
  get: ({ get }) => {
    const parentState = get(sequentialLoadingState);

    if (!parentState.didomi) {
      return false;
    }

    if (CommonUtils.parseBoolean(process.env.NEXT_PUBLIC_ENABLE_COUNTRY_DETECT) && !parentState.countryDetect) {
      return false;
    }

    return true;
  },
});
