import { useSearchParams } from "next/navigation";
import { useMemo } from "react";

import CrossCountry from "~/types/crossCountry";

type CultureLinkProps = {
  children: React.ReactNode;
  className?: string | undefined;
  country: CrossCountry.CountryType;
  culture: CrossCountry.CultureType;
};

export function CultureLink(props: CultureLinkProps) {
  const searchParams = useSearchParams();
  const utmParameters = useMemo(() => {
    const parameters: string[] = [];

    searchParams.forEach((value, key) => {
      if (key.startsWith("utm_")) {
        parameters.push(`${key}=${value}`);
      }
    });

    return parameters.length > 0 ? `?${parameters.join("&")}` : "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.toString()]);

  return props.country.isUnifiedCommerce ? (
    <a href={`/${props.culture.locale}${utmParameters}`} className={props.className}>
      {props.children}
    </a>
  ) : (
    <a href={props.culture.href + utmParameters} className={props.className}>
      {props.children}
    </a>
  );
}
