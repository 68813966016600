const enabledLocales = [
  "ar-ae",
  "en-ae",
  "en-us",
  "it-it",
  "fr-fr",
  "de-de",
  "en-gb",
  "nl-nl",
  "de-ch",
  "it-ch",
  "fr-ch",
  "es-es",
  "fr-be",
  "nl-be",
];

const localeConfig = {
  contentfulLocales: enabledLocales.reduce((acc, l) => {
    const parts = l.split("-");
    acc[l] = `${parts[0]}-${parts[1].toUpperCase()}`;

    return acc;
  }, {}),
  enabledLocales,
  middleEastLocales: ["ar-qa", "en-qa", "ar-kw", "en-kw", "ar-bh", "en-bh", "ar-om", "en-om", "ar-jo", "en-jo"],
  nextLocales: ["default", ...enabledLocales],
};

module.exports = localeConfig;
