import aa from "search-insights";

function getUserTokens() {
  let currentUserToken;
  let currentAuthenticatedUserToken;
  aa("getUserToken", {}, (err, userToken) => {
    if (err) {
      console.error(err);
      return;
    }
    currentUserToken = userToken;
  });

  aa("getAuthenticatedUserToken", {}, (err, authenticatedUserToken) => {
    if (err) {
      console.error(err);
      return;
    }
    currentAuthenticatedUserToken = authenticatedUserToken;
  });

  return {
    currentUserToken,
    currentAuthenticatedUserToken,
  };
}

export function algoliaAddToBag(algoliaIndexId: string, productId: number, trackingData: any, quantity = 1) {
  const { currentUserToken, currentAuthenticatedUserToken } = getUserTokens();

  let eventPayload = {
    userToken: currentUserToken,
    authenticatedUserToken: currentAuthenticatedUserToken,
    eventName: "add to cart after search",
    index: algoliaIndexId,
    objectIDs: [`${productId}`],
    objectData: [
      {
        price: trackingData?.price?.toFixed(2),
        discount: trackingData?.discount ? trackingData?.discount.toFixed(2) : undefined,
        quantity: quantity,
      },
    ],
    value: trackingData?.price,
    currency: trackingData?.currency,
  } as any;

  const currentQueryId = sessionStorage.getItem("algolia_queryid");
  if (currentQueryId !== null && currentQueryId != "") {
    aa("addedToCartObjectIDsAfterSearch", {
      ...eventPayload,
      queryID: currentQueryId,
    });

    sessionStorage.removeItem("algolia_queryid");
    localStorage.removeItem("algolia_queryid");

    // save added products with relative queryId in localstorage for purchasedObjectIDsAfterSearch event
    const currentCartQueryIds = localStorage.getItem("algolia_cart_queryids");
    let newAlgoliaCartQueryids = {} as any;
    if (currentCartQueryIds) {
      newAlgoliaCartQueryids = JSON.parse(currentCartQueryIds);
    }
    newAlgoliaCartQueryids[`${productId}`] = currentQueryId;
    localStorage.setItem("algolia_cart_queryids", JSON.stringify(newAlgoliaCartQueryids));
  } else {
    aa("addedToCartObjectIDs", eventPayload);
  }
}

type algoliaOrderData = {
  currency: string;
  total: number;
  items: {
    id: number;
    quantity: number;
    price: number;
    discount: number;
  }[];
};

export function algoliaPurchasedAfterSearch(algoliaIndexId: string, algoliaOrderData: algoliaOrderData) {
  const { currentUserToken, currentAuthenticatedUserToken } = getUserTokens();

  const raw = localStorage.getItem("algolia_cart_queryids");
  if (raw) {
    const currentCartQueryIds = JSON.parse(raw);
    if (currentCartQueryIds) {
      aa("purchasedObjectIDsAfterSearch", {
        userToken: currentUserToken,
        authenticatedUserToken: currentAuthenticatedUserToken,
        eventName: "Purchase completed after search",
        index: algoliaIndexId,
        objectIDs: algoliaOrderData.items.map((item) => `${item.id}`),
        objectData: algoliaOrderData.items.map((item) => ({
          queryID: currentCartQueryIds[item.id],
          price: item.price.toFixed(2),
          discount: item.discount ? Math.abs(item.discount).toFixed(2) : undefined,
          quantity: item.quantity,
        })),
        value: algoliaOrderData.total,
        currency: algoliaOrderData.currency,
      });

      localStorage.removeItem("algolia_cart_queryids");
    }
  }
}
