import { Entry } from "contentful";
import { NextRouter } from "next/router";

import { TextStyle } from "~/enums";
import Editorials from "~/types/editorials";

import crossCountryUtils from "./crossCountry-utils";
import { isActiveEntry } from "./editorial-utils";

type TextStylesConfiguration = {
  styles: TextStyle[];
  tag: String[];
};

type StyledTextToken = {
  token: string;
  style: TextStyle;
};

const DEFAULT_ALT_STYLE_CONFIGS: TextStylesConfiguration = {
  styles: [TextStyle.DEFAULT, TextStyle.HIGHLIGHTED],
  tag: ["%", "%"], // more performant than using charAt on a string -> use chars not strings
};

/**
 * Creates an array of text tokens with a bound style reference
 * @param inText - the text to tokenize
 * @param configs - the configurations
 * @returns - an array of text tokens with a bound style reference
 */
function getStyledTextTokens(
  inText: string,
  configs: TextStylesConfiguration = DEFAULT_ALT_STYLE_CONFIGS
): StyledTextToken[] {
  let tagHead: number = 0;
  let styleHead: number = 0;
  let isEscaping: boolean = false;
  let tokens: StyledTextToken[] = [];
  let token: string = "";
  let ambiguousChunk: string = "";
  for (let head: number = 0; head < inText.length; head++) {
    const charAt: string = inText[head];
    if (charAt === configs.tag[tagHead]) {
      if (isEscaping) {
        isEscaping = false;
        token += charAt;
      } else if (++tagHead >= configs.tag.length) {
        tagHead = 0;
        if (token.length > 0) {
          tokens.push({
            token: token,
            style: configs.styles[styleHead % configs.styles.length],
          });
        }
        token = "";
        ambiguousChunk = "";
        styleHead++;
      } else {
        ambiguousChunk += charAt;
        if (head === inText.length - 1) {
          token += ambiguousChunk;
        }
      }
    } else {
      tagHead = 0;
      token += ambiguousChunk + charAt;
      ambiguousChunk = "";
    }
  }

  if (token.length > 0) {
    tokens.push({
      token: token,
      style: configs.styles[styleHead++ % configs.styles.length],
    });
  }
  return tokens;
}

function escapeHtmlParagraph(htmlString: string): string {
  return htmlString.replace(/<p>/g, "").replace(/<\/p>/g, "");
}

function sanitizeContentfulUrl(link: Entry<Editorials.Link>, router: NextRouter) {
  if (!isActiveEntry(link)) {
    return "#";
  }

  const pageRef = link.fields.pageRef?.published ? link.fields.pageRef : undefined;

  if (pageRef) {
    const contentfulLocale = crossCountryUtils.getCurrentLocaleWithCountryUppercase(router);
    if (pageRef.contentType === "categoryBinding") {
      return "/c/" + pageRef.slugs[contentfulLocale] + "/";
    }
    if (pageRef.contentType === "editorialPage") {
      return "/" + pageRef.slugs[contentfulLocale] + "/";
    }
  }

  return link.fields.url ?? "#";
}

const textUtils = {
  getStyledTextTokens: getStyledTextTokens,
  escapeHtmlParagraph,
  sanitizeContentfulUrl,
};

export default textUtils;
