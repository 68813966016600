import { Expose, instanceToPlain, Transform } from "class-transformer";

import { GenericResponseBase } from "~/shared/api/generic-response-base.interface";

import { ResponseMessage } from "./response-message.class";

export class GenericResponse<T> implements GenericResponseBase<T> {
  @Expose()
  @Transform(({ value }) => (value.length ? value : void 0))
  get messages(): ResponseMessage[] | void {
    return this._messages;
  }

  constructor(protected _messages: ResponseMessage[] = [], public data?: T) {}

  isError(): boolean {
    return !this.isSuccess();
  }

  isSuccess(): boolean {
    return typeof (this as any)["_errorCode"] === typeof 0;
  }

  serialize(): GenericResponseBase {
    return instanceToPlain(this, {
      excludePrefixes: ["_"],
    }) as GenericResponseBase;
  }
}
