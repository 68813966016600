import Script from "next/script";
import { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import aa from "search-insights";

import { useAuthContext } from "~/contexts/auth";
import useAuth from "~/hooks/use-auth";

type VendorConsents = Record<string, boolean>;
type PurposesConsents = Record<string, boolean>;

type ContextProps = PropsWithChildren;
type ContextAttrs = {
  vendorConsents: VendorConsents;
  purposesConsents: PurposesConsents;
  updateVendor: (id: string, value: boolean) => void;
  updatePurpose: (id: string, value: boolean) => void;
};

const Context = createContext<ContextAttrs>({
  vendorConsents: {},
  purposesConsents: {},
  updateVendor: () => {},
  updatePurpose: () => {},
});

export default function UserConsentsProvider(props: Readonly<ContextProps>) {
  const { user, isAuthenticated } = useAuthContext();
  const [vendorConsents, setVendorConsents] = useState<VendorConsents>({});
  const [purposesConsents, setPurposesConsents] = useState<PurposesConsents>({});

  // effect to enable/disable algolia trackings via cookie
  useEffect(() => {
    if (Object.keys(purposesConsents).length > 0) {
      aa("init", {
        partial: true,
        useCookie: !!purposesConsents["profiling"],
      });
    }
  }, [purposesConsents]);

  // effect to use the user.ID as algolia AuthenticatedUserToken
  useEffect(() => {
    if (user && isAuthenticated) {
      aa("setAuthenticatedUserToken", `${user.ID}`);
    }
  }, [user, isAuthenticated]);

  const updateVendor = (id: string, value: boolean) => {
    const newConsents = { ...vendorConsents };
    newConsents[id] = value;
    setVendorConsents(newConsents);
  };

  const updatePurpose = (id: string, value: boolean) => {
    const newConsents = { ...purposesConsents };
    newConsents[id] = value;
    setPurposesConsents(newConsents);
  };

  return (
    <Context.Provider
      value={{
        vendorConsents,
        purposesConsents,
        updateVendor,
        updatePurpose,
      }}
    >
      {props.children}
      {/* CDP Personalization */}
      {purposesConsents["profiling"] && <Script src={process.env.NEXT_PUBLIC_EVERGAGE_SCRIPT} />}
    </Context.Provider>
  );
}

export function useConsents() {
  const context = useContext(Context);
  if (!context) {
    throw new Error(`useConsents must be used within a UserConsentsProvider`);
  }

  return context;
}
