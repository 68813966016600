import { uniq } from "ramda";

import Eva from "~/services/eva";
import Logger from "~/services/logger/logger";
import CrossCountry from "~/types/crossCountry";

import Constants from "./constants";
import productUtils from "./product-utils";

const evaUtils = {
  enrichProductSearchResponse(response: EVA.Core.SearchProductsResponse) {
    if (!response.Products) {
      return response;
    }

    const allDiscounts = response.Options?.PrefiguredDiscounts?.filter((d) => d.PromotionLabel).map((d) => ({
      DiscountID: d.DiscountID,
      PromotionLabel: d.PromotionLabel!,
      EligibleProductIDs: d.EligibleProductIDs,
    }));

    for (const product of response.Products) {
      const availability = response.Options?.AvailabilityResult?.[product.product_id];

      if (availability) {
        product.is_available = availability?.Delivery?.IsAvailable || false;
        product.has_stock = availability?.Delivery?.HasStock || false;
      }

      const discounts = allDiscounts?.filter((d) => d.EligibleProductIDs.includes(product.product_id));

      product.discounts = discounts;
      product.product_types = productUtils.getProductType(product.product_types);
    }

    // Remove these objects because the data has already been used to enrich the products.
    delete response.Options?.PrefiguredDiscounts;
    delete response.Options?.AvailabilityResult;

    return response;
  },
  async enrichProductSearchResponseWithChildren(eva: Eva, response: EVA.Core.SearchProductsResponse) {
    response = this.enrichProductSearchResponse(response)!;

    if (!response.Products) {
      return response as Omit<EVA.Core.SearchProductsResponse, "Products"> & { Products?: CrossCountry.Product[] };
    }

    const parents = response.Products.filter((p) => p.logical_level === "root");
    const parentIds = parents.map((p) => p.product_id);

    if (parents.length > 0) {
      const children = await evaUtils.getChildrenProducts(eva, parentIds);
      const partitioned = productUtils.partitionChildrenProductsByParent(children);

      for (const product of response.Products) {
        product.children = partitioned[product.product_id];
      }
    }

    return response as Omit<EVA.Core.SearchProductsResponse, "Products"> & { Products?: CrossCountry.Product[] };
  },

  /**
   * Used internally in the util
   * @param eva EVA instance
   * @param parentIds array of parent IDs
   * @returns children products
   */
  async getChildrenProducts(eva: Eva, parentIds: string[]) {
    const searchProducts = eva.getChildrenProducts(parentIds, 1, Constants.MAXIMUM_PAGE_SIZE);
    const response = this.enrichProductSearchResponse(await searchProducts);
    const numberOfPages = Math.ceil(response.Total! / Constants.MAXIMUM_PAGE_SIZE);

    let products = response.Products ?? [];

    for (let page = 2; page < numberOfPages + 1; page++) {
      const pageResponse = await eva.getChildrenProducts(parentIds, page, Constants.MAXIMUM_PAGE_SIZE);
      const richResponse = this.enrichProductSearchResponse(pageResponse);

      products = products.concat(richResponse.Products ?? []);
    }

    return products;
  },
  async getProductDetailWithChildren(eva: Eva, productId: string) {
    const root = (await eva.getRootProductDetail(productId)).Result;

    if (!root) {
      return null;
    }

    const children = await evaUtils.getChildrenProducts(eva, [root.product_id]);

    const rootAvailability = await eva.getProductAvailability([{ ID: root.product_id }], false);

    root.is_available = rootAvailability?.Products[0].Delivery?.IsAvailable || false;
    root.has_stock = rootAvailability?.Products[0].Delivery?.HasStock || false;

    // remap product types
    root.product_types = productUtils.getProductType(root.product_types);

    let selected = root;

    if (children.length > 0) {
      if (root.product_id.toString() !== productId) {
        // The product is already a specific shade.
        selected = (await eva.getProductDetail(productId)).Result;
      } else {
        // The product is a parent, we need to fetch the first shade.
        selected = (await eva.getProductDetail(children[0].product_id)).Result;
      }

      if (selected.is_available === undefined) {
        const selectedAvailability = await eva.getProductAvailability([{ ID: selected.product_id }], false);

        selected.is_available = selectedAvailability?.Products[0].Delivery?.IsAvailable || false;
        selected.has_stock = selectedAvailability?.Products[0].Delivery?.HasStock || false;
      }

      // remap product types
      selected.product_types = productUtils.getProductType(selected.product_types);
    }

    selected.discounts = await eva.getProductDiscounts(selected.product_id);

    return { root, children, selected };
  },

  async getProductSlugs(eva: Eva) {
    const BATCH_SIZE = 20;

    const productList: CrossCountry.HierarchicalProduct[] = [];
    let filtered: CrossCountry.HierarchicalProduct[] = [];

    let lastParentId: number | undefined;
    let page = 1;
    let products = (await eva.getGroupedProductSlugs(page))?.Products;
    while (products?.length) {
      // work in batches to avoid excessive array size
      while (products?.length && page % BATCH_SIZE !== 0) {
        productList.push(...products);
        products = (await eva.getGroupedProductSlugs(++page))?.Products;
      }
      productList.push(...(products || []));
      // first childs are then sought binarily: separate the array in two
      // (keep in mind that binary search is more efficient with bigger batches)
      const rIdx = Math.ceil(productList.length / 2);
      try {
        filtered.push(
          ...productUtils.extractFirstColorProductBinary(productList, 0, rIdx, productList.length, lastParentId)
        );
      } catch (e) {
        console.error("Error computing sitemap products slugs batch.", e);
      }
      lastParentId = productList[productList.length - 1].logical_level_hierarchy?.[0].product_id;
      productList.length = 0;
      products = (await eva.getGroupedProductSlugs(++page))?.Products;
    }
    return filtered;
  },

  async getProductsByIdsWithChildren(
    eva: Eva,
    ids: string[],
    sort = Eva.DEFAULT_CATEGORY_SORT,
    page = 1,
    pageSize = Constants.DEFAULT_PAGE_SIZE
  ) {
    const base = await eva.getProductsByIds(ids, sort, page, pageSize);
    return this.enrichProductSearchResponseWithChildren(eva, base);
  },
  async getProductsByCategoryWithChildren(
    eva: Eva,
    category: string,
    sort = Eva.DEFAULT_CATEGORY_SORT,
    page = 1,
    pageSize = Constants.DEFAULT_PAGE_SIZE
  ) {
    const base = await eva.getProductsByCategory(category, sort, page, pageSize);
    return this.enrichProductSearchResponseWithChildren(eva, base);
  },
  async getProductsByQueryWithChildren(
    eva: Eva,
    query: string,
    sort = Eva.DEFAULT_CATEGORY_SORT,
    page = 1,
    pageSize = Constants.DEFAULT_PAGE_SIZE
  ) {
    const base = await eva.getProductsByQuery(query, sort, page, pageSize);
    return this.enrichProductSearchResponseWithChildren(eva, base);
  },
  async getProductsBySetWithChildren(
    eva: Eva,
    set: number,
    sort = Eva.DEFAULT_CATEGORY_SORT,
    page = 1,
    pageSize = Constants.DEFAULT_PAGE_SIZE
  ) {
    const base = await eva.getProductsBySet(set, sort, page, pageSize);
    return this.enrichProductSearchResponseWithChildren(eva, base);
  },

  getCustomFieldConfiguration(backendId: string, configuration: EVA.Core.GetApplicationConfiguration) {
    const allCustomFields = Object.values(configuration.ExtendedCustomFields).flat();
    return allCustomFields.find((cf) => cf.BackendID === backendId);
  },
  getCustomFieldValueForResource(
    backendId: string,
    resource: { CustomFieldValuesWithOptions?: { [key: number]: EVA.Core.CustomFieldValueWithOptions } },
    configuration: EVA.Core.GetApplicationConfiguration
  ) {
    const customField = this.getCustomFieldConfiguration(backendId, configuration);

    if (!customField) {
      return undefined;
    }

    const customFieldValue = resource.CustomFieldValuesWithOptions?.[customField.CustomFieldID];

    if (!customFieldValue?.Value) {
      if (customFieldValue?.Options.DefaultCustomFieldValue) {
        const defaultKey = Object.keys(customFieldValue?.Options.DefaultCustomFieldValue);
        return customFieldValue?.Options.DefaultCustomFieldValue[defaultKey[0] as keyof EVA.Core.CustomFieldValue];
      } else {
        return undefined;
      }
    }

    switch (customField.DataType) {
      case "String":
      case "Text":
      case "Enum":
        return customFieldValue.Value.StringValue;
      case "Date":
        return customFieldValue.Value.DateTimeValue;
      case "Bool":
        return customFieldValue.Value.BoolValue;
      case "Integer":
      case "Decimal":
        return customFieldValue.Value.NumberValue;
      default:
        console.warn(`Unexpected custom field type: ${customField.DataType}`);
        return undefined;
    }
  },
  getCustomFieldEnumValueForResource(
    backendId: string,
    resource: { CustomFieldValuesWithOptions?: { [key: number]: EVA.Core.CustomFieldValueWithOptions } },
    configuration: EVA.Core.GetApplicationConfiguration
  ) {
    const customField = this.getCustomFieldConfiguration(backendId, configuration);

    if (!customField?.EnumValues) {
      return undefined;
    }

    const customFieldValue = resource.CustomFieldValuesWithOptions?.[customField.CustomFieldID];

    if (!customFieldValue?.Value) {
      return undefined;
    }

    return customField.EnumValues[customFieldValue.Value.StringValue as string];
  },
};

export default evaUtils;
